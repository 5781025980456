import React from "react";
import Layout from '../../components/Layout'
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";

import { Link,graphql,navigate } from "gatsby";
import Video from "../../components/Video";
import BackgroundImage from "gatsby-background-image";
import Accordion from "../../components/Accordion";


const IndustryExpertisesPage = ({ data }) => {
  const daa =data.strapiIndustryExpertises
//   const ProductsList = data.allStrapiProducts.nodes
//   const servicesList= data.allStrapiServices.nodes
  return (
    <Layout title={data.strapiIndustryExpertises.title}>
      <div className="services">
      <BackgroundImage
        backgroundColor={`#0080FF`}
        fluid={daa.image?.localFile.childImageSharp.fluid}
         className="hero">
         <div className="overlay">
           <h1>{daa.title}</h1>
          </div>
          </BackgroundImage>

        <div className="ssbody wrapper_max">
          <div className="content">
            <h2>{daa.title}</h2>
            <div className='page-body' dangerouslySetInnerHTML={{__html: daa.body}} />
            <Accordion data={daa.section}/>

          </div>
        </div>
      </div>
      {daa.youtubeLink!=null&&daa.youtubeLink!="null"&&daa.youtubeLink!=""&&<Video url={daa.youtubeLink}/>}
    </Layout>
  );
};

export const query = graphql`
  query xyz($slug: String!) {
    strapiIndustryExpertises(slug: { eq: $slug }) {
            id
            slug
            title
            body
            youtubeLink
            section{
              body
              title
            }
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 90,maxWidth: 1900){
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
    }
  }
`;

export default IndustryExpertisesPage;
